.firstFilterContainer{
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.filter-content{
  position: absolute;
  top: 200px;
  left: 20px;
  max-width: max-content;
}
.filter-container, .secondSidebarContainer{
    background: white;
    border: 1px solid #3D7CC9;
    border-radius: 8px;
    width: 18vw;
}

.first-sidebar-title, .second-sidebar-title{
    color: #3D7CC9;
    border-bottom: 1px solid #3D7CC9;
    margin: 0;
    font-size: 0.9rem;
}

.first-sidebar-title{
    padding: 4px 20px;
}

.second-sidebar-title{
    text-align: center;
    border-radius: 6px;
    padding: 6px 0;
}

.filter-container>div{
    padding: 6px 20px;
    border-bottom: 1px solid #3D7CC9;
    color: #00263E;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
}

.filter-container>div:last-child{
    border-bottom: none;
}

.active-filter,.filter-container>div:hover{
    background-color: #3D7CC9;
    color: white !important;
}

/* second sidebar css */
.secondSidebarContainer{
    height: 375px;
}

.statusItem{
    margin: 0 16px;
    font-size: 0.8rem;
}

.statusItem>p{
    padding: 2px 10px;
    border-radius: 8px;
    cursor: pointer;
}

.bdContainer{
    margin: 12px 20px;
    border-top: 1px solid #3D7CC9;
    border-bottom: 1px solid #3D7CC9;
}

input[type='checkbox']{
    border: 1px solid #3D7CC9;
    margin: 3% 2%;
}

.bdContainer>label{
    font-size: 0.9rem;
}

.bdContainer>form{
    height: 265px;
    overflow-y: scroll;
}

.bdContainer>form::-webkit-scrollbar{
    background: none;
    width: 0;
}

.confirmBtn{
    background-color: #3D7CC9;
    color: white;
    margin-inline: auto;
    border: none;
    display: block;
    border-radius: 4px;
    box-shadow: #00000029 0px 2px 5px;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 80px;
}


.rdrMonth {
    width: unset
  }
  
  .patientFilterDatePicker {
    width: 100%;
    margin-top: -10px;
  }
  
  
  .rdrCalendarWrapper {
    width: 100%;
    font-size: 10px;
    border:1px solid #3d7cc9;
    margin-top: 10px;
    border-radius: 5px;
  }
  .rdrDateRangePickerWrapper{
    width:100%
  }
  .rdrMonthAndYearWrapper{
    height:fit-content;
    margin-top:0px;
    padding-top: 0px;
  }
  
  .rdrMonth{
    padding:unset;
    font-size: 10px;
    border:1px solid #3d7cc9;
    border-radius: 5px;
    border-left:0px;
    border-right: 0px;
    border-bottom:0px;
  
  
  }
  .rdrMonthPicker {
    font-size: 10px;
  }
  .rdrMonthPicker select {
  border-right:1px solid #3d7cc9;
  }
  .rdrYearPicker select{
    border-left:1px solid #3d7cc9;
  
  }
  .patientFilterDatePicker .rdrDay{
  height:2.5em
  }
  .rdrDayToday .rdrDayNumber span:after {
  bottom:-1px
  }
  .rdrMonthAndYearPickers{
    border:1px solid #3d7cc9;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom:0px;
    border-radius: 5px;
    background:#F5F5F5
  }
  
  .rdrCalendarWrapper {
    box-sizing: border-box;
    background: #ffffff;
    display: inline-flex;
    flex-direction: column;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  .rdrDateDisplay{
    display: flex;
    justify-content: space-between;
  }
  
  .rdrDateDisplayItem{
    flex: 1 1;
    width: 0;
    text-align: center;
    color: inherit;
  }
  
  .rdrDateDisplayItem + .rdrDateDisplayItem{
      margin-left: 0.833em;
    }
  
  .rdrDateDisplayItem input{
      text-align: inherit
    }
  
  .rdrDateDisplayItem input:disabled{
        cursor: default;
      }
  
  .rdrDateDisplayItemActive{}
  
  .rdrMonthAndYearWrapper {
    box-sizing: inherit;
    display: flex;
    justify-content: space-between;
  }
  
  .rdrMonthAndYearPickers{
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rdrMonthPicker{}
  
  .rdrYearPicker{}
  
  .rdrNextPrevButton {
    box-sizing: inherit;
    cursor: pointer;
    outline: none;
  }
  
  .rdrPprevButton {}
  
  .rdrNextButton {}
  
  .rdrMonths{
    display: flex;
  }
  
  .rdrMonthsVertical{
    flex-direction: column;
  }
  
  .rdrMonthsHorizontal > div > div > div{
    display: flex;
    flex-direction: row;
  }
  
  .rdrMonth{
    width: 27.667em;
  }
  
  .rdrWeekDays{
    display: flex;
  }
  
  .rdrWeekDay {
    flex-basis: calc(100% / 7);
    box-sizing: inherit;
    text-align: center;
  }
  
  .rdrDays{
    display: flex;
    flex-wrap: wrap;
  }
  
  .rdrDateDisplayWrapper{}
  
  .rdrMonthName{}
  
  .rdrInfiniteMonths{
    overflow: auto;
  }
  
  .rdrDateRangeWrapper{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  .rdrDateInput {
    position: relative;
  }
  
  .rdrDateInput input {
      outline: none;
    }
  
  .rdrDateInput .rdrWarning {
      position: absolute;
      font-size: 1.6em;
      line-height: 1.6em;
      top: 0;
      right: .25em;
      color: #FF0000;
    }
  
  .rdrDay {
    box-sizing: inherit;
    width: calc(100% / 7);
    position: relative;
    font: inherit;
    cursor: pointer;
  }
  
  .rdrDayNumber {
    display: block;
    position: relative;
  }
  
  .rdrDayNumber span{
      color: #1d2429;
    }
  
  .rdrDayDisabled {
    cursor: not-allowed;
  }
  
  @supports (-ms-ime-align: auto) {
    .rdrDay {
      flex-basis: 14.285% !important;
    }
  }
  
  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
    pointer-events: none;
  }
  
  .rdrInRange{}
  
  .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
    pointer-events: none;
  }
  
  .rdrDayHovered{}
  
  .rdrDayActive{}
  
  .rdrDateRangePickerWrapper{
    display: inline-flex;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  .rdrDefinedRangesWrapper{}
  
  .rdrStaticRanges{
    display: flex;
    flex-direction: column;
  }
  
  .rdrStaticRange{
    font-size: inherit;
  }
  
  .rdrStaticRangeLabel{}
  
  .rdrInputRanges{}
  
  .rdrInputRange{
    display: flex;
  }
  
  .rdrInputRangeInput{}

  .rdrCalendarWrapper{
    color: #000000;
    font-size: 12px;
  }
  
  .rdrDateDisplayWrapper{
    background-color: rgb(239, 242, 247);
  }
  
  .rdrDateDisplay{
    margin: 0.833em;
  }
  
  .rdrDateDisplayItem{
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
    border: 1px solid transparent;
  }
  
  .rdrDateDisplayItem input{
      cursor: pointer;
      height: 2.5em;
      line-height: 2.5em;
      border: 0px;
      background: transparent;
      width: 100%;
      color: #849095;
    }
  
  .rdrDateDisplayItemActive{
    border-color: currentColor;
  }
  
  .rdrDateDisplayItemActive input{
      color: #7d888d
    }
  
  .rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
    padding-top: 10px;
  }
  
  .rdrMonthAndYearPickers{
    font-weight: 600;
  }
  
  .rdrMonthAndYearPickers select{
      -moz-appearance: none;
           appearance: none;
      -webkit-appearance: none;
      border: 0;
      background: transparent;
      padding: 10px 30px 10px 10px;
      border-radius: 4px;
      outline: 0;
      color: #3e484f;
      background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
      background-position: right 8px center;
      cursor: pointer;
      text-align: center
    }
  
  .rdrMonthAndYearPickers select:hover{
        background-color: rgba(0,0,0,0.07);
      }
  
  .rdrMonthPicker, .rdrYearPicker{
    margin: 0 5px
  }
  
  .rdrNextPrevButton {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 0.833em;
    padding: 0;
    border: 0;
    border-radius: 5px;
    background: #EFF2F7
  }
  
  .rdrNextPrevButton:hover{
      background: #E1E7F0;
    }
  
  .rdrNextPrevButton i {
      display: block;
      width: 0;
      height: 0;
      padding: 0;
      text-align: center;
      border-style: solid;
      margin: auto;
      transform: translate(-3px, 0px);
    }
  
  .rdrPprevButton i {
      border-width: 4px 6px 4px 4px;
      border-color: transparent rgb(52, 73, 94) transparent transparent;
      transform: translate(-3px, 0px);
    }
  
  .rdrNextButton i {
      margin: 0 0 0 7px;
      border-width: 4px 4px 4px 6px;
      border-color: transparent transparent transparent rgb(52, 73, 94);
      transform: translate(3px, 0px);
    }
  
  .rdrWeekDays {
    padding: 0 0.833em;
  }
  
  .rdrMonth{
    padding: 0 0.833em 1.666em 0.833em;
  }
  
  .rdrMonth .rdrWeekDays {
      padding: 0;
    }
  
  .rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
    display: none;
  }
  
  .rdrWeekDay {
    font-weight: 400;
    line-height: 2.667em;
    color: rgb(132, 144, 149);
  }
  
  .rdrDay {
    background: transparent;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border: 0;
    padding: 0;
    line-height: 3.000em;
    height: 3.000em;
    text-align: center;
    color: #1d2429
  }
  
  .rdrDay:focus {
      outline: 0;
    }
  
  .rdrDayNumber {
    outline: 0;
    font-weight: 300;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    top: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .rdrDayToday .rdrDayNumber span{
    font-weight: 500
  }
  
  .rdrDayToday .rdrDayNumber span:after{
      content: '';
      position: absolute;
      bottom: 4px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 18px;
      height: 2px;
      border-radius: 2px;
      background: #3d91ff;
    }
  
  .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after{
        background: #fff;
      }
  
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
            color: rgba(255, 255, 255, 0.85);
          }
  
  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
    background: currentColor;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 5px;
  }
  
  .rdrSelected{
    left: 2px;
    right: 2px;
  }
  
  .rdrInRange{}
  
  .rdrStartEdge{
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
  }
  
  .rdrEndEdge{
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
  }
  
  .rdrSelected{
    border-radius: 1.042em;
  }
  
  .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge{
      border-top-left-radius: 1.042em;
      border-bottom-left-radius: 1.042em;
      left: 2px;
    }
  
  .rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge{
      border-top-right-radius: 1.042em;
      border-bottom-right-radius: 1.042em;
      right: 2px;
    }
  
  .rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
      border-top-left-radius: 1.333em;
      border-bottom-left-radius: 1.333em;
      border-left-width: 1px;
      left: 0px;
    }
  
  .rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
     border-top-right-radius: 1.333em;
     border-bottom-right-radius: 1.333em;
     border-right-width: 1px;
     right: 0px;
   }
  
  .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
    background: rgba(255, 255, 255, 0.09);
    position: absolute;
    top: 3px;
    left: 0px;
    right: 0px;
    bottom: 3px;
    pointer-events: none;
    border: 0px solid currentColor;
    z-index: 1;
  }
  
  .rdrDayStartPreview{
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    left: 0px;
  }
  
  .rdrDayInPreview{
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  
  .rdrDayEndPreview{
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-right-radius: 1.333em;
    border-bottom-right-radius: 1.333em;
    right: 2px;
    right: 0px;
  }
  
  .rdrDefinedRangesWrapper{
    font-size: 12px;
    width: 226px;
    border-right: solid 1px #eff2f7;
    background: #fff;
  }
  
  .rdrDefinedRangesWrapper .rdrStaticRangeSelected{
      color: currentColor;
      font-weight: 600;
    }
  
  .rdrStaticRange{
    border: 0;
    cursor: pointer;
    display: block;
    outline: 0;
    border-bottom: 1px solid #eff2f7;
    padding: 0;
    background: #fff
  }
  
  .rdrStaticRange:hover .rdrStaticRangeLabel,.rdrStaticRange:focus .rdrStaticRangeLabel{
        background: #eff2f7;
      }
  
  .rdrStaticRangeLabel{
    display: block;
    outline: 0;
    line-height: 18px;
    padding: 10px 20px;
    text-align: left;
  }
  
  .rdrInputRanges{
    padding: 10px 0;
  }
  
  .rdrInputRange{
    align-items: center;
    padding: 5px 20px;
  }
  
  .rdrInputRangeInput{
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    border: solid 1px rgb(222, 231, 235);
    margin-right: 10px;
    color: rgb(108, 118, 122)
  }
  
  .rdrInputRangeInput:focus, .rdrInputRangeInput:hover{
      border-color: rgb(180, 191, 196);
      outline: 0;
      color: #333;
    }
  
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
    content: '';
    border: 1px solid currentColor;
    border-radius: 1.333em;
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: 0px;
    right: 0px;
    background: transparent;
  }
  
  .rdrDayPassive{
    pointer-events: none;
  }
  
  .rdrDayPassive .rdrDayNumber span{
      color: #d5dce0;
    }
  
  .rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview{
      display: none;
    }
  
  .rdrDayDisabled {
    background-color: rgb(248, 248, 248);
  }
  
  .rdrDayDisabled .rdrDayNumber span{
      color: #aeb9bf;
    }
  
  .rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview{
      filter: grayscale(100%) opacity(60%);
    }
  
  .rdrMonthName{
    text-align: left;
    font-weight: 600;
    color: #849095;
    padding: 0.833em;
  }
  
  .rdrCalendarWrapper{
    color: #000000;
    font-size: 12px;
  }
  
  .rdrDateDisplayWrapper{
    background-color: rgb(239, 242, 247);
  }
  
  .rdrDateDisplay{
    margin: 0.833em;
  }
  
  .rdrDateDisplayItem{
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
    border: 1px solid transparent;
  }
  
  .rdrDateDisplayItem input{
      cursor: pointer;
      height: 2.5em;
      line-height: 2.5em;
      border: 0px;
      background: transparent;
      width: 100%;
      color: #849095;
    }
  
  .rdrDateDisplayItemActive{
    border-color: currentColor;
  }
  
  .rdrDateDisplayItemActive input{
      color: #7d888d
    }
  
  .rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
    padding-top: 10px;
  }
  
  .rdrMonthAndYearPickers{
    font-weight: 600;
  }
  
  .rdrMonthAndYearPickers select{
      -moz-appearance: none;
           appearance: none;
      -webkit-appearance: none;
      border: 0;
      background: transparent;
      padding: 10px 30px 10px 10px;
      border-radius: 4px;
      outline: 0;
      color: #3e484f;
      background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
      background-position: right 8px center;
      cursor: pointer;
      text-align: center
    }
  
  .rdrMonthAndYearPickers select:hover{
        background-color: rgba(0,0,0,0.07);
      }
  
  .rdrMonthPicker, .rdrYearPicker{
    margin: 0 5px
  }
  
  .rdrNextPrevButton {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 0.833em;
    padding: 0;
    border: 0;
    border-radius: 5px;
    background: #EFF2F7
  }
  
  .rdrNextPrevButton:hover{
      background: #E1E7F0;
    }
  
  .rdrNextPrevButton i {
      display: block;
      width: 0;
      height: 0;
      padding: 0;
      text-align: center;
      border-style: solid;
      margin: auto;
      transform: translate(-3px, 0px);
    }
  
  .rdrPprevButton i {
      border-width: 4px 6px 4px 4px;
      border-color: transparent rgb(52, 73, 94) transparent transparent;
      transform: translate(-3px, 0px);
    }
  
  .rdrNextButton i {
      margin: 0 0 0 7px;
      border-width: 4px 4px 4px 6px;
      border-color: transparent transparent transparent rgb(52, 73, 94);
      transform: translate(3px, 0px);
    }
  
  .rdrWeekDays {
    padding: 0 0.833em;
  }
  
  .rdrMonth{
    padding: 0 0.833em 1.666em 0.833em;
  }
  
  .rdrMonth .rdrWeekDays {
      padding: 0;
    }
  
  .rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
    display: none;
  }
  
  .rdrWeekDay {
    font-weight: 400;
    line-height: 2.667em;
    color: rgb(132, 144, 149);
  }
  
  .rdrDay {
    background: transparent;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border: 0;
    padding: 0;
    line-height: 3.000em;
    height: 3.000em;
    text-align: center;
    color: #1d2429
  }
  
  .rdrDay:focus {
      outline: 0;
    }
  
  .rdrDayNumber {
    outline: 0;
    font-weight: 300;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    top: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .rdrDayToday .rdrDayNumber span{
    font-weight: 500
  }
  
  .rdrDayToday .rdrDayNumber span:after{
      content: '';
      position: absolute;
      bottom: 4px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 18px;
      height: 2px;
      border-radius: 2px;
      background: #3d91ff;
    }
  
  .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after{
        background: #fff;
      }
  
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
            color: rgba(255, 255, 255, 0.85);
          }
  
  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
    background: currentColor;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 5px;
  }
  
  .rdrSelected{
    left: 2px;
    right: 2px;
  }
  
  .rdrInRange{}
  
  .rdrStartEdge{
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
  }
  
  .rdrEndEdge{
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
  }
  
  .rdrSelected{
    border-radius: 1.042em;
  }
  
  .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge{
      border-top-left-radius: 1.042em;
      border-bottom-left-radius: 1.042em;
      left: 2px;
    }
  
  .rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge{
      border-top-right-radius: 1.042em;
      border-bottom-right-radius: 1.042em;
      right: 2px;
    }
  
  .rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
      border-top-left-radius: 1.333em;
      border-bottom-left-radius: 1.333em;
      border-left-width: 1px;
      left: 0px;
    }
  
  .rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
     border-top-right-radius: 1.333em;
     border-bottom-right-radius: 1.333em;
     border-right-width: 1px;
     right: 0px;
   }
  
  .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
    background: rgba(255, 255, 255, 0.09);
    position: absolute;
    top: 3px;
    left: 0px;
    right: 0px;
    bottom: 3px;
    pointer-events: none;
    border: 0px solid currentColor;
    z-index: 1;
  }
  
  .rdrDayStartPreview{
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    left: 0px;
  }
  
  .rdrDayInPreview{
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  
  .rdrDayEndPreview{
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-right-radius: 1.333em;
    border-bottom-right-radius: 1.333em;
    right: 2px;
    right: 0px;
  }
  
  .rdrDefinedRangesWrapper{
    font-size: 12px;
    width: 226px;
    border-right: solid 1px #eff2f7;
    background: #fff;
  }
  
  .rdrDefinedRangesWrapper .rdrStaticRangeSelected{
      color: currentColor;
      font-weight: 600;
    }
  
  .rdrStaticRange{
    border: 0;
    cursor: pointer;
    display: block;
    outline: 0;
    border-bottom: 1px solid #eff2f7;
    padding: 0;
    background: #fff
  }
  
  .rdrStaticRange:hover .rdrStaticRangeLabel,.rdrStaticRange:focus .rdrStaticRangeLabel{
        background: #eff2f7;
      }
  
  .rdrStaticRangeLabel{
    display: block;
    outline: 0;
    line-height: 18px;
    padding: 10px 20px;
    text-align: left;
  }
  
  .rdrInputRanges{
    padding: 10px 0;
  }
  
  .rdrInputRange{
    align-items: center;
    padding: 5px 20px;
  }
  
  .rdrInputRangeInput{
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    border: solid 1px rgb(222, 231, 235);
    margin-right: 10px;
    color: rgb(108, 118, 122)
  }
  
  .rdrInputRangeInput:focus, .rdrInputRangeInput:hover{
      border-color: rgb(180, 191, 196);
      outline: 0;
      color: #333;
    }
  
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
    content: '';
    border: 1px solid currentColor;
    border-radius: 1.333em;
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: 0px;
    right: 0px;
    background: transparent;
  }
  
  .rdrDayPassive{
    pointer-events: none;
  }
  
  .rdrDayPassive .rdrDayNumber span{
      color: #d5dce0;
    }
  
  .rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview{
      display: none;
    }
  
  .rdrDayDisabled {
    background-color: rgb(248, 248, 248);
  }
  
  .rdrDayDisabled .rdrDayNumber span{
      color: #aeb9bf;
    }
  
  .rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview{
      filter: grayscale(100%) opacity(60%);
    }
  
  .rdrMonthName{
    text-align: left;
    font-weight: 600;
    color: #849095;
    padding: 0.833em;
  }
  .rdrCalendarWrapper{
  color: #000000;
  font-size: 12px;
}

.rdrDateDisplayWrapper{
  background-color: rgb(239, 242, 247);
}

.rdrDateDisplay{
  margin: 0.833em;
}

.rdrDateDisplayItem{
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
}

.rdrDateDisplayItem input{
    cursor: pointer;
    height: 2.5em;
    line-height: 2.5em;
    border: 0px;
    background: transparent;
    width: 100%;
    color: #849095;
  }

.rdrDateDisplayItemActive{
  border-color: currentColor;
}

.rdrDateDisplayItemActive input{
    color: #7d888d
  }

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers{
  font-weight: 600;
}

.rdrMonthAndYearPickers select{
    -moz-appearance: none;
         appearance: none;
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    outline: 0;
    color: #3e484f;
    background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    text-align: center
  }

.rdrMonthAndYearPickers select:hover{
      background-color: rgba(0,0,0,0.07);
    }

.rdrMonthPicker, .rdrYearPicker{
  margin: 0 5px
}

.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: #EFF2F7
}

.rdrNextPrevButton:hover{
    background: #E1E7F0;
  }

.rdrNextPrevButton i {
    display: block;
    width: 0;
    height: 0;
    padding: 0;
    text-align: center;
    border-style: solid;
    margin: auto;
    transform: translate(-3px, 0px);
  }

.rdrPprevButton i {
    border-width: 4px 6px 4px 4px;
    border-color: transparent rgb(52, 73, 94) transparent transparent;
    transform: translate(-3px, 0px);
  }

.rdrNextButton i {
    margin: 0 0 0 7px;
    border-width: 4px 4px 4px 6px;
    border-color: transparent transparent transparent rgb(52, 73, 94);
    transform: translate(3px, 0px);
  }

.rdrWeekDays {
  padding: 0 0.833em;
}

.rdrMonth{
  padding: 0 0.833em 1.666em 0.833em;
}

.rdrMonth .rdrWeekDays {
    padding: 0;
  }

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
  display: none;
}

.rdrWeekDay {
  font-weight: 400;
  line-height: 2.667em;
  color: rgb(132, 144, 149);
}

.rdrDay {
  background: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 0;
  padding: 0;
  line-height: 3.000em;
  height: 3.000em;
  text-align: center;
  color: #1d2429
}

.rdrDay:focus {
    outline: 0;
  }

.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  top: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDayToday .rdrDayNumber span{
  font-weight: 500
}

.rdrDayToday .rdrDayNumber span:after{
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: #3d91ff;
  }

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after{
      background: #fff;
    }

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
          color: rgba(255, 255, 255, 0.85);
        }

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  background: currentColor;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
}

.rdrSelected{
  left: 2px;
  right: 2px;
}

.rdrInRange{}

.rdrStartEdge{
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrEndEdge{
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrSelected{
  border-radius: 1.042em;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge{
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
  }

.rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge{
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
  }

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    border-left-width: 1px;
    left: 0px;
  }

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
   border-top-right-radius: 1.333em;
   border-bottom-right-radius: 1.333em;
   border-right-width: 1px;
   right: 0px;
 }

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  background: rgba(255, 255, 255, 0.09);
  position: absolute;
  top: 3px;
  left: 0px;
  right: 0px;
  bottom: 3px;
  pointer-events: none;
  border: 0px solid currentColor;
  z-index: 1;
}

.rdrDayStartPreview{
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0px;
}

.rdrDayInPreview{
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview{
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 2px;
  right: 0px;
}

.rdrDefinedRangesWrapper{
  font-size: 12px;
  width: 226px;
  border-right: solid 1px #eff2f7;
  background: #fff;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected{
    color: currentColor;
    font-weight: 600;
  }

.rdrStaticRange{
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  border-bottom: 1px solid #eff2f7;
  padding: 0;
  background: #fff
}

.rdrStaticRange:hover .rdrStaticRangeLabel,.rdrStaticRange:focus .rdrStaticRangeLabel{
      background: #eff2f7;
    }

.rdrStaticRangeLabel{
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
}

.rdrInputRanges{
  padding: 10px 0;
}

.rdrInputRange{
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput{
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  border: solid 1px rgb(222, 231, 235);
  margin-right: 10px;
  color: rgb(108, 118, 122)
}

.rdrInputRangeInput:focus, .rdrInputRangeInput:hover{
    border-color: rgb(180, 191, 196);
    outline: 0;
    color: #333;
  }

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
  content: '';
  border: 1px solid currentColor;
  border-radius: 1.333em;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  background: transparent;
}

.rdrDayPassive{
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span{
    color: #d5dce0;
  }

.rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview{
    display: none;
  }

.rdrDayDisabled {
  background-color: rgb(248, 248, 248);
}

.rdrDayDisabled .rdrDayNumber span{
    color: #aeb9bf;
  }

.rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview{
    filter: grayscale(100%) opacity(60%);
  }

.rdrMonthName{
  text-align: left;
  font-weight: 600;
  color: #849095;
  padding: 0.833em;
}


.filtersContainer {
    display: flex;
    padding: 10px 10px 10px 0px;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .filter {
    text-transform: none !important;
    box-shadow: 5px 5px 15px #0000000d !important;
    border-radius: 5px !important;
    padding: 0.5em 1em 0.5em 1em !important;
    /* color: #3d7cc9 !important;
    background: white !important; */
    /* font-size: 18px!important; */
    margin-bottom: 10px !important;
    white-space: nowrap;
    justify-content: flex-start!important;
  }
  .rdrDefinedRangesWrapper {
    display: none !important;
  }
  .backArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 11px;
    color: grey;
  }
  
  .filterSearch {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 25px 20px 0px 0px;
  }
  
  .filterActionButton {
    margin-left: 10px !important;
    box-shadow: 5px 5px 15px #0000000d !important;
    border-radius: 5px !important;
    padding: 0.5em 1em 0.5em 1em !important;
    font-size: 18px !important;
    margin-bottom: 10px !important;
    text-transform: none !important;
    font-size: calc(
      7px + (20 - 12) * ((100vw - 360px) / (1920 - 360))
    ) !important;
    height:50px;
    width:9.1vw
  }
  
  .inputBase> div>.MuiInputBase-input::placeholder{
    color:"#3D7CC9";
    padding-left:10px;
    opacity: unset!important;
    font-size: calc(
      7px + (20 - 12) * ((100vw - 360px) / (1920 - 360))
    ) !important;}
  
    .inputBase> div>.MuiInputBase-input{
      font-size: calc(
        7px + (20 - 12) * ((100vw - 360px) / (1920 - 360))
      ) !important;
    }
  .checklistFilter{
    height:50px;
    width:18.2vw;
  }
  .checklistFilter>Button{
    height:100%;
    width:100%;
    font-size: calc( 7px + (20 - 12) * ((100vw - 360px) / (1920 - 360)))!important;
  
  }
  .optionContainer{
    font-size: 14px;
   border-radius: 0px!important;
  }
  .optionListContainer {
    border-radius: 0px!important;
  
  }
  
  .option:hover{
    /* background:#3D7CC9!important */
  }
  
  .optionContainer>.option{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .optionContainer>.selected{
    background:none!important;
    color:black!important
  }

.first-sidebar-title, .second-sidebar-title{
    color: #3D7CC9;
    border-bottom: 1px solid #3D7CC9;
    margin: 0;
    font-size: 0.9rem;
}

.first-sidebar-title{
    padding: 4px 20px;
}

.second-sidebar-title{
    text-align: center;
    border-radius: 6px;
    padding: 6px 0;
}

.filter-container>div{
    padding: 6px 20px;
    border-bottom: 1px solid #3D7CC9;
    color: #00263E;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
}

.filter-container>div:last-child{
    border-bottom: none;
}

.active-filter,.filter-container>div:hover{
    background-color: #3D7CC9;
    color: white !important;
}

/* second sidebar css */
.secondSidebarContainer{
    height: 375px;
}

.statusItem{
    margin: 0 16px;
    font-size: 0.8rem;
}

.statusItem>p{
    padding: 2px 10px;
    border-radius: 8px;
    cursor: pointer;
}

.bdContainer{
    margin: 12px 20px;
    border-top: 1px solid #3D7CC9;
    border-bottom: 1px solid #3D7CC9;
}

input[type='checkbox']{
    border: 1px solid #3D7CC9;
    margin: 3% 2%;
}

.bdContainer>label{
    font-size: 0.9rem;
}

.bdContainer>form{
    height: 265px;
    overflow-y: scroll;
}

.bdContainer>form::-webkit-scrollbar{
    background: none;
    width: 0;
}

.confirmBtn{
    background-color: #3D7CC9;
    color: white;
    margin-inline: auto;
    border: none;
    display: block;
    border-radius: 4px;
    box-shadow: #00000029 0px 2px 5px;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 80px;
}


.rdrMonth {
    width: unset
  }
  
  .patientFilterDatePicker {
    width: 100%;
    margin-top: -10px;
  }
  
  
  .rdrCalendarWrapper {
    width: 100%;
    font-size: 10px;
    border:1px solid #3d7cc9;
    margin-top: 10px;
    border-radius: 5px;
  }
  .rdrDateRangePickerWrapper{
    width:100%
  }
  .rdrMonthAndYearWrapper{
    height:fit-content;
    margin-top:0px;
    padding-top: 0px;
  }
  
  .rdrMonth{
    padding:unset;
    font-size: 10px;
    border:1px solid #3d7cc9;
    border-radius: 5px;
    border-left:0px;
    border-right: 0px;
    border-bottom:0px;
  
  
  }
  .rdrMonthPicker {
    font-size: 10px;
  }
  .rdrMonthPicker select {
  border-right:1px solid #3d7cc9;
  }
  .rdrYearPicker select{
    border-left:1px solid #3d7cc9;
  
  }
  .patientFilterDatePicker .rdrDay{
  height:2.5em
  }
  .rdrDayToday .rdrDayNumber span:after {
  bottom:-1px
  }
  .rdrMonthAndYearPickers{
    border:1px solid #3d7cc9;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom:0px;
    border-radius: 5px;
    background:#F5F5F5
  }
  
  .rdrCalendarWrapper {
    box-sizing: border-box;
    background: #ffffff;
    display: inline-flex;
    flex-direction: column;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  .rdrDateDisplay{
    display: flex;
    justify-content: space-between;
  }
  
  .rdrDateDisplayItem{
    flex: 1 1;
    width: 0;
    text-align: center;
    color: inherit;
  }
  
  .rdrDateDisplayItem + .rdrDateDisplayItem{
      margin-left: 0.833em;
    }
  
  .rdrDateDisplayItem input{
      text-align: inherit
    }
  
  .rdrDateDisplayItem input:disabled{
        cursor: default;
      }
  
  .rdrDateDisplayItemActive{}
  
  .rdrMonthAndYearWrapper {
    box-sizing: inherit;
    display: flex;
    justify-content: space-between;
  }
  
  .rdrMonthAndYearPickers{
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rdrMonthPicker{}
  
  .rdrYearPicker{}
  
  .rdrNextPrevButton {
    box-sizing: inherit;
    cursor: pointer;
    outline: none;
  }
  
  .rdrPprevButton {}
  
  .rdrNextButton {}
  
  .rdrMonths{
    display: flex;
  }
  
  .rdrMonthsVertical{
    flex-direction: column;
  }
  
  .rdrMonthsHorizontal > div > div > div{
    display: flex;
    flex-direction: row;
  }
  
  .rdrMonth{
    width: 27.667em;
  }
  
  .rdrWeekDays{
    display: flex;
  }
  
  .rdrWeekDay {
    flex-basis: calc(100% / 7);
    box-sizing: inherit;
    text-align: center;
  }
  
  .rdrDays{
    display: flex;
    flex-wrap: wrap;
  }
  
  .rdrDateDisplayWrapper{}
  
  .rdrMonthName{}
  
  .rdrInfiniteMonths{
    overflow: auto;
  }
  
  .rdrDateRangeWrapper{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  .rdrDateInput {
    position: relative;
  }
  
  .rdrDateInput input {
      outline: none;
    }
  
  .rdrDateInput .rdrWarning {
      position: absolute;
      font-size: 1.6em;
      line-height: 1.6em;
      top: 0;
      right: .25em;
      color: #FF0000;
    }
  
  .rdrDay {
    box-sizing: inherit;
    width: calc(100% / 7);
    position: relative;
    font: inherit;
    cursor: pointer;
  }
  
  .rdrDayNumber {
    display: block;
    position: relative;
  }
  
  .rdrDayNumber span{
      color: #1d2429;
    }
  
  .rdrDayDisabled {
    cursor: not-allowed;
  }
  
  @supports (-ms-ime-align: auto) {
    .rdrDay {
      flex-basis: 14.285% !important;
    }
  }
  
  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
    pointer-events: none;
  }
  
  .rdrInRange{}
  
  .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
    pointer-events: none;
  }
  
  .rdrDayHovered{}
  
  .rdrDayActive{}
  
  .rdrDateRangePickerWrapper{
    display: inline-flex;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  .rdrDefinedRangesWrapper{}
  
  .rdrStaticRanges{
    display: flex;
    flex-direction: column;
  }
  
  .rdrStaticRange{
    font-size: inherit;
  }
  
  .rdrStaticRangeLabel{}
  
  .rdrInputRanges{}
  
  .rdrInputRange{
    display: flex;
  }
  
  .rdrInputRangeInput{}

  .rdrCalendarWrapper{
    color: #000000;
    font-size: 12px;
  }
  
  .rdrDateDisplayWrapper{
    background-color: rgb(239, 242, 247);
  }
  
  .rdrDateDisplay{
    margin: 0.833em;
  }
  
  .rdrDateDisplayItem{
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
    border: 1px solid transparent;
  }
  
  .rdrDateDisplayItem input{
      cursor: pointer;
      height: 2.5em;
      line-height: 2.5em;
      border: 0px;
      background: transparent;
      width: 100%;
      color: #849095;
    }
  
  .rdrDateDisplayItemActive{
    border-color: currentColor;
  }
  
  .rdrDateDisplayItemActive input{
      color: #7d888d
    }
  
  .rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
    padding-top: 10px;
  }
  
  .rdrMonthAndYearPickers{
    font-weight: 600;
  }
  
  .rdrMonthAndYearPickers select{
      -moz-appearance: none;
           appearance: none;
      -webkit-appearance: none;
      border: 0;
      background: transparent;
      padding: 10px 30px 10px 10px;
      border-radius: 4px;
      outline: 0;
      color: #3e484f;
      background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
      background-position: right 8px center;
      cursor: pointer;
      text-align: center
    }
  
  .rdrMonthAndYearPickers select:hover{
        background-color: rgba(0,0,0,0.07);
      }
  
  .rdrMonthPicker, .rdrYearPicker{
    margin: 0 5px
  }
  
  .rdrNextPrevButton {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 0.833em;
    padding: 0;
    border: 0;
    border-radius: 5px;
    background: #EFF2F7
  }
  
  .rdrNextPrevButton:hover{
      background: #E1E7F0;
    }
  
  .rdrNextPrevButton i {
      display: block;
      width: 0;
      height: 0;
      padding: 0;
      text-align: center;
      border-style: solid;
      margin: auto;
      transform: translate(-3px, 0px);
    }
  
  .rdrPprevButton i {
      border-width: 4px 6px 4px 4px;
      border-color: transparent rgb(52, 73, 94) transparent transparent;
      transform: translate(-3px, 0px);
    }
  
  .rdrNextButton i {
      margin: 0 0 0 7px;
      border-width: 4px 4px 4px 6px;
      border-color: transparent transparent transparent rgb(52, 73, 94);
      transform: translate(3px, 0px);
    }
  
  .rdrWeekDays {
    padding: 0 0.833em;
  }
  
  .rdrMonth{
    padding: 0 0.833em 1.666em 0.833em;
  }
  
  .rdrMonth .rdrWeekDays {
      padding: 0;
    }
  
  .rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
    display: none;
  }
  
  .rdrWeekDay {
    font-weight: 400;
    line-height: 2.667em;
    color: rgb(132, 144, 149);
  }
  
  .rdrDay {
    background: transparent;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border: 0;
    padding: 0;
    line-height: 3.000em;
    height: 3.000em;
    text-align: center;
    color: #1d2429
  }
  
  .rdrDay:focus {
      outline: 0;
    }
  
  .rdrDayNumber {
    outline: 0;
    font-weight: 300;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    top: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .rdrDayToday .rdrDayNumber span{
    font-weight: 500
  }
  
  .rdrDayToday .rdrDayNumber span:after{
      content: '';
      position: absolute;
      bottom: 4px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 18px;
      height: 2px;
      border-radius: 2px;
      background: #3d91ff;
    }
  
  .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after{
        background: #fff;
      }
  
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
            color: rgba(255, 255, 255, 0.85);
          }
  
  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
    background: currentColor;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 5px;
  }
  
  .rdrSelected{
    left: 2px;
    right: 2px;
  }
  
  .rdrStartEdge{
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
  }
  
  .rdrEndEdge{
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
  }
  
  .rdrSelected{
    border-radius: 1.042em;
  }
  
  .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge{
      border-top-left-radius: 1.042em;
      border-bottom-left-radius: 1.042em;
      left: 2px;
    }
  
  .rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge{
      border-top-right-radius: 1.042em;
      border-bottom-right-radius: 1.042em;
      right: 2px;
    }
  
  .rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
      border-top-left-radius: 1.333em;
      border-bottom-left-radius: 1.333em;
      border-left-width: 1px;
      left: 0px;
    }
  
  .rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
     border-top-right-radius: 1.333em;
     border-bottom-right-radius: 1.333em;
     border-right-width: 1px;
     right: 0px;
   }
  
  .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
    background: rgba(255, 255, 255, 0.09);
    position: absolute;
    top: 3px;
    left: 0px;
    right: 0px;
    bottom: 3px;
    pointer-events: none;
    border: 0px solid currentColor;
    z-index: 1;
  }
  
  .rdrDayStartPreview{
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    left: 0px;
  }
  
  .rdrDayInPreview{
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  
  .rdrDayEndPreview{
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-right-radius: 1.333em;
    border-bottom-right-radius: 1.333em;
    right: 2px;
    right: 0px;
  }
  
  .rdrDefinedRangesWrapper{
    font-size: 12px;
    width: 226px;
    border-right: solid 1px #eff2f7;
    background: #fff;
  }
  
  .rdrDefinedRangesWrapper .rdrStaticRangeSelected{
      color: currentColor;
      font-weight: 600;
    }
  
  .rdrStaticRange{
    border: 0;
    cursor: pointer;
    display: block;
    outline: 0;
    border-bottom: 1px solid #eff2f7;
    padding: 0;
    background: #fff
  }
  
  .rdrStaticRange:hover .rdrStaticRangeLabel,.rdrStaticRange:focus .rdrStaticRangeLabel{
        background: #eff2f7;
      }
  
  .rdrStaticRangeLabel{
    display: block;
    outline: 0;
    line-height: 18px;
    padding: 10px 20px;
    text-align: left;
  }
  
  .rdrInputRanges{
    padding: 10px 0;
  }
  
  .rdrInputRange{
    align-items: center;
    padding: 5px 20px;
  }
  
  .rdrInputRangeInput{
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    border: solid 1px rgb(222, 231, 235);
    margin-right: 10px;
    color: rgb(108, 118, 122)
  }
  
  .rdrInputRangeInput:focus, .rdrInputRangeInput:hover{
      border-color: rgb(180, 191, 196);
      outline: 0;
      color: #333;
    }
  
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
    content: '';
    border: 1px solid currentColor;
    border-radius: 1.333em;
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: 0px;
    right: 0px;
    background: transparent;
  }
  
  .rdrDayPassive{
    pointer-events: none;
  }
  
  .rdrDayPassive .rdrDayNumber span{
      color: #d5dce0;
    }
  
  .rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview{
      display: none;
    }
  
  .rdrDayDisabled {
    background-color: rgb(248, 248, 248);
  }
  
  .rdrDayDisabled .rdrDayNumber span{
      color: #aeb9bf;
    }
  
  .rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview{
      filter: grayscale(100%) opacity(60%);
    }
  
  .rdrMonthName{
    text-align: left;
    font-weight: 600;
    color: #849095;
    padding: 0.833em;
  }
  
  .rdrCalendarWrapper{
    color: #000000;
    font-size: 12px;
  }
  
  .rdrDateDisplayWrapper{
    background-color: rgb(239, 242, 247);
  }
  
  .rdrDateDisplay{
    margin: 0.833em;
  }
  
  .rdrDateDisplayItem{
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
    border: 1px solid transparent;
  }
  
  .rdrDateDisplayItem input{
      cursor: pointer;
      height: 2.5em;
      line-height: 2.5em;
      border: 0px;
      background: transparent;
      width: 100%;
      color: #849095;
    }
  
  .rdrDateDisplayItemActive{
    border-color: currentColor;
  }
  
  .rdrDateDisplayItemActive input{
      color: #7d888d
    }
  
  .rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
    padding-top: 10px;
  }
  
  .rdrMonthAndYearPickers{
    font-weight: 600;
  }
  
  .rdrMonthAndYearPickers select{
      -moz-appearance: none;
           appearance: none;
      -webkit-appearance: none;
      border: 0;
      background: transparent;
      padding: 10px 30px 10px 10px;
      border-radius: 4px;
      outline: 0;
      color: #3e484f;
      background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
      background-position: right 8px center;
      cursor: pointer;
      text-align: center
    }
  
  .rdrMonthAndYearPickers select:hover{
        background-color: rgba(0,0,0,0.07);
      }
  
  .rdrMonthPicker, .rdrYearPicker{
    margin: 0 5px
  }
  
  .rdrNextPrevButton {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 0.833em;
    padding: 0;
    border: 0;
    border-radius: 5px;
    background: #EFF2F7
  }
  
  .rdrNextPrevButton:hover{
      background: #E1E7F0;
    }
  
  .rdrNextPrevButton i {
      display: block;
      width: 0;
      height: 0;
      padding: 0;
      text-align: center;
      border-style: solid;
      margin: auto;
      transform: translate(-3px, 0px);
    }
  
  .rdrPprevButton i {
      border-width: 4px 6px 4px 4px;
      border-color: transparent rgb(52, 73, 94) transparent transparent;
      transform: translate(-3px, 0px);
    }
  
  .rdrNextButton i {
      margin: 0 0 0 7px;
      border-width: 4px 4px 4px 6px;
      border-color: transparent transparent transparent rgb(52, 73, 94);
      transform: translate(3px, 0px);
    }
  
  .rdrWeekDays {
    padding: 0 0.833em;
  }
  
  .rdrMonth{
    padding: 0 0.833em 1.666em 0.833em;
  }
  
  .rdrMonth .rdrWeekDays {
      padding: 0;
    }
  
  .rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
    display: none;
  }
  
  .rdrWeekDay {
    font-weight: 400;
    line-height: 2.667em;
    color: rgb(132, 144, 149);
  }
  
  .rdrDay {
    background: transparent;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border: 0;
    padding: 0;
    line-height: 3.000em;
    height: 3.000em;
    text-align: center;
    color: #1d2429
  }
  
  .rdrDay:focus {
      outline: 0;
    }
  
  .rdrDayNumber {
    outline: 0;
    font-weight: 300;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    top: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .rdrDayToday .rdrDayNumber span{
    font-weight: 500
  }
  
  .rdrDayToday .rdrDayNumber span:after{
      content: '';
      position: absolute;
      bottom: 4px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 18px;
      height: 2px;
      border-radius: 2px;
      background: #3d91ff;
    }
  
  .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after{
        background: #fff;
      }
  
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
            color: rgba(255, 255, 255, 0.85);
          }
  
  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
    background: currentColor;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 5px;
  }
  
  .rdrSelected{
    left: 2px;
    right: 2px;
  }
  
  .rdrInRange{}
  
  .rdrStartEdge{
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
  }
  
  .rdrEndEdge{
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
  }
  
  .rdrSelected{
    border-radius: 1.042em;
  }
  
  .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge{
      border-top-left-radius: 1.042em;
      border-bottom-left-radius: 1.042em;
      left: 2px;
    }
  
  .rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge{
      border-top-right-radius: 1.042em;
      border-bottom-right-radius: 1.042em;
      right: 2px;
    }
  
  .rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
      border-top-left-radius: 1.333em;
      border-bottom-left-radius: 1.333em;
      border-left-width: 1px;
      left: 0px;
    }
  
  .rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
     border-top-right-radius: 1.333em;
     border-bottom-right-radius: 1.333em;
     border-right-width: 1px;
     right: 0px;
   }
  
  .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
    background: rgba(255, 255, 255, 0.09);
    position: absolute;
    top: 3px;
    left: 0px;
    right: 0px;
    bottom: 3px;
    pointer-events: none;
    border: 0px solid currentColor;
    z-index: 1;
  }
  
  .rdrDayStartPreview{
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    left: 0px;
  }
  
  .rdrDayInPreview{
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  
  .rdrDayEndPreview{
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-right-radius: 1.333em;
    border-bottom-right-radius: 1.333em;
    right: 2px;
    right: 0px;
  }
  
  .rdrDefinedRangesWrapper{
    font-size: 12px;
    width: 226px;
    border-right: solid 1px #eff2f7;
    background: #fff;
  }
  
  .rdrDefinedRangesWrapper .rdrStaticRangeSelected{
      color: currentColor;
      font-weight: 600;
    }
  
  .rdrStaticRange{
    border: 0;
    cursor: pointer;
    display: block;
    outline: 0;
    border-bottom: 1px solid #eff2f7;
    padding: 0;
    background: #fff
  }
  
  .rdrStaticRange:hover .rdrStaticRangeLabel,.rdrStaticRange:focus .rdrStaticRangeLabel{
        background: #eff2f7;
      }
  
  .rdrStaticRangeLabel{
    display: block;
    outline: 0;
    line-height: 18px;
    padding: 10px 20px;
    text-align: left;
  }
  
  .rdrInputRanges{
    padding: 10px 0;
  }
  
  .rdrInputRange{
    align-items: center;
    padding: 5px 20px;
  }
  
  .rdrInputRangeInput{
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    border: solid 1px rgb(222, 231, 235);
    margin-right: 10px;
    color: rgb(108, 118, 122)
  }
  
  .rdrInputRangeInput:focus, .rdrInputRangeInput:hover{
      border-color: rgb(180, 191, 196);
      outline: 0;
      color: #333;
    }
  
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
    content: '';
    border: 1px solid currentColor;
    border-radius: 1.333em;
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: 0px;
    right: 0px;
    background: transparent;
  }
  
  .rdrDayPassive{
    pointer-events: none;
  }
  
  .rdrDayPassive .rdrDayNumber span{
      color: #d5dce0;
    }
  
  .rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview{
      display: none;
    }
  
  .rdrDayDisabled {
    background-color: rgb(248, 248, 248);
  }
  
  .rdrDayDisabled .rdrDayNumber span{
      color: #aeb9bf;
    }
  
  .rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview{
      filter: grayscale(100%) opacity(60%);
    }
  
  .rdrMonthName{
    text-align: left;
    font-weight: 600;
    color: #849095;
    padding: 0.833em;
  }
  .rdrCalendarWrapper{
  color: #000000;
  font-size: 12px;
}

.rdrDateDisplayWrapper{
  background-color: rgb(239, 242, 247);
}

.rdrDateDisplay{
  margin: 0.833em;
}

.rdrDateDisplayItem{
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
}

.rdrDateDisplayItem input{
    cursor: pointer;
    height: 2.5em;
    line-height: 2.5em;
    border: 0px;
    background: transparent;
    width: 100%;
    color: #849095;
  }

.rdrDateDisplayItemActive{
  border-color: currentColor;
}

.rdrDateDisplayItemActive input{
    color: #7d888d
  }

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers{
  font-weight: 600;
}

.rdrMonthAndYearPickers select{
    -moz-appearance: none;
         appearance: none;
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    outline: 0;
    color: #3e484f;
    background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    text-align: center
  }

.rdrMonthAndYearPickers select:hover{
      background-color: rgba(0,0,0,0.07);
    }

.rdrMonthPicker, .rdrYearPicker{
  margin: 0 5px
}

.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: #EFF2F7
}

.rdrNextPrevButton:hover{
    background: #E1E7F0;
  }

.rdrNextPrevButton i {
    display: block;
    width: 0;
    height: 0;
    padding: 0;
    text-align: center;
    border-style: solid;
    margin: auto;
    transform: translate(-3px, 0px);
  }

.rdrPprevButton i {
    border-width: 4px 6px 4px 4px;
    border-color: transparent rgb(52, 73, 94) transparent transparent;
    transform: translate(-3px, 0px);
  }

.rdrNextButton i {
    margin: 0 0 0 7px;
    border-width: 4px 4px 4px 6px;
    border-color: transparent transparent transparent rgb(52, 73, 94);
    transform: translate(3px, 0px);
  }

.rdrWeekDays {
  padding: 0 0.833em;
}

.rdrMonth{
  padding: 0 0.833em 1.666em 0.833em;
}

.rdrMonth .rdrWeekDays {
    padding: 0;
  }

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
  display: none;
}

.rdrWeekDay {
  font-weight: 400;
  line-height: 2.667em;
  color: rgb(132, 144, 149);
}

.rdrDay {
  background: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 0;
  padding: 0;
  line-height: 3.000em;
  height: 3.000em;
  text-align: center;
  color: #1d2429
}

.rdrDay:focus {
    outline: 0;
  }

.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  top: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDayToday .rdrDayNumber span{
  font-weight: 500
}

.rdrDayToday .rdrDayNumber span:after{
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: #3d91ff;
  }

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after{
      background: #fff;
    }

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
          color: rgba(255, 255, 255, 0.85);
        }

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  background: currentColor;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
}

.rdrSelected{
  left: 2px;
  right: 2px;
}

.rdrInRange{}

.rdrStartEdge{
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrEndEdge{
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrSelected{
  border-radius: 1.042em;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge{
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
  }

.rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge{
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
  }

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    border-left-width: 1px;
    left: 0px;
  }

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
   border-top-right-radius: 1.333em;
   border-bottom-right-radius: 1.333em;
   border-right-width: 1px;
   right: 0px;
 }

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  background: rgba(255, 255, 255, 0.09);
  position: absolute;
  top: 3px;
  left: 0px;
  right: 0px;
  bottom: 3px;
  pointer-events: none;
  border: 0px solid currentColor;
  z-index: 1;
}

.rdrDayStartPreview{
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0px;
}

.rdrDayInPreview{
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview{
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 2px;
  right: 0px;
}

.rdrDefinedRangesWrapper{
  font-size: 12px;
  width: 226px;
  border-right: solid 1px #eff2f7;
  background: #fff;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected{
    color: currentColor;
    font-weight: 600;
  }

.rdrStaticRange{
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  border-bottom: 1px solid #eff2f7;
  padding: 0;
  background: #fff
}

.rdrStaticRange:hover .rdrStaticRangeLabel,.rdrStaticRange:focus .rdrStaticRangeLabel{
      background: #eff2f7;
    }

.rdrStaticRangeLabel{
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
}

.rdrInputRanges{
  padding: 10px 0;
}

.rdrInputRange{
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput{
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  border: solid 1px rgb(222, 231, 235);
  margin-right: 10px;
  color: rgb(108, 118, 122)
}

.rdrInputRangeInput:focus, .rdrInputRangeInput:hover{
    border-color: rgb(180, 191, 196);
    outline: 0;
    color: #333;
  }

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
  content: '';
  border: 1px solid currentColor;
  border-radius: 1.333em;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  background: transparent;
}

.rdrDayPassive{
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span{
    color: #d5dce0;
  }

.rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview{
    display: none;
  }

.rdrDayDisabled {
  background-color: rgb(248, 248, 248);
}

.rdrDayDisabled .rdrDayNumber span{
    color: #aeb9bf;
  }

.rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview{
    filter: grayscale(100%) opacity(60%);
  }

.rdrMonthName{
  text-align: left;
  font-weight: 600;
  color: #849095;
  padding: 0.833em;
}


.filtersContainer {
    display: flex;
    padding: 10px 10px 10px 0px;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .filter {
    text-transform: none !important;
    box-shadow: 5px 5px 15px #0000000d !important;
    border-radius: 5px !important;
    padding: 0.5em 1em 0.5em 1em !important;
    /* color: #3d7cc9 !important;
    background: white !important; */
    /* font-size: 18px!important; */
    margin-bottom: 10px !important;
    white-space: nowrap;
    justify-content: flex-start!important;
  }
  .rdrDefinedRangesWrapper {
    display: none !important;
  }
  .backArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 11px;
    color: grey;
  }
  
  .filterSearch {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 25px 20px 0px 0px;
  }
  
  .filterActionButton {
    margin-left: 10px !important;
    box-shadow: 5px 5px 15px #0000000d !important;
    border-radius: 5px !important;
    padding: 0.5em 1em 0.5em 1em !important;
    font-size: 18px !important;
    margin-bottom: 10px !important;
    text-transform: none !important;
    font-size: calc(
      7px + (20 - 12) * ((100vw - 360px) / (1920 - 360))
    ) !important;
    height:50px;
    width:9.1vw
  }
  
  .inputBase> div>.MuiInputBase-input::placeholder{
    color:"#3D7CC9";
    padding-left:10px;
    opacity: unset!important;
    font-size: calc(
      7px + (20 - 12) * ((100vw - 360px) / (1920 - 360))
    ) !important;}
  
    .inputBase> div>.MuiInputBase-input{
      font-size: calc(
        7px + (20 - 12) * ((100vw - 360px) / (1920 - 360))
      ) !important;
    }
  .checklistFilter{
    height:50px;
    width:18.2vw;
  }
  .checklistFilter>Button{
    height:100%;
    width:100%;
    font-size: calc( 7px + (20 - 12) * ((100vw - 360px) / (1920 - 360)))!important;
  
  }
  .optionContainer{
    font-size: 14px;
   border-radius: 0px!important;
  }
  .optionListContainer {
    border-radius: 0px!important;
  
  }
  
  .option:hover{
    /* background:#3D7CC9!important */
  }
  
  .optionContainer>.option{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .optionContainer>.selected{
    background:none!important;
    color:black!important
  }
  
  
    


 .rdrMonth {
    width: unset
}

.patientFilterDatePicker {
    width: 100%;
    margin-top: -10px;
}


 .rdrCalendarWrapper {
    width: 100%;
    font-size: 10px;
    border:1px solid #3d7cc9;
    margin-top: 10px;
    border-radius: 5px;
}
.rdrDateRangePickerWrapper{
    width:100%
}
 .rdrMonthAndYearWrapper{
    height:fit-content;
    margin-top:0px;
    padding-top: 0px;
}

 .rdrMonth{
    padding:unset;
    font-size: 10px;
    border:1px solid #3d7cc9;
    border-radius: 5px;
    border-left:0px;
    border-right: 0px;
    border-bottom:0px;


}
.rdrMonthPicker {
    font-size: 10px;
}
.rdrMonthPicker select {
border-right:1px solid #3d7cc9;
}
.rdrYearPicker select{
    border-left:1px solid #3d7cc9;

}
.patientFilterDatePicker .rdrDay{
height:2.5em
}
 .rdrDayToday .rdrDayNumber span:after {
bottom:-1px
}
.rdrMonthAndYearPickers{
    border:1px solid #3d7cc9;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom:0px;
    border-radius: 5px;
    background:#F5F5F5
}
