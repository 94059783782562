.header {
  background-color: #fff;
  color: white;
  text-align: center;
  padding: .5rem;
  color: #555;
}

.content {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tasktype {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  font-weight: bold;
  width: 150px;
}

select,
input[type="text"],
input[type="date"] {
  width: 100%;
  /* padding: 0.5rem; */
  border: 1px solid #79b5ff;
  border-radius: 4px;
  /* height: 1rem; */
}

button {
  background-color: #333;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #555;
}
.title-h {
  height: 4vh;
}
.formContainer{
  justify-content: center;
  height: calc(100vh - 200px);
  overflow-y: scroll;
}
.RadioBtnLabel{
  width: unset;
  font-weight: bold !important;
  color: black !important;  
}
.RadioBtnLabel span{
font-size: 14px;
}
.labelGrid{
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.dropImageDiv{
  width: 300px;

}
.create-btn-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
@media only screen and (max-width:760px) {
.header-title{
  left: 36%;
}
.selectRightColWid{
  height: 3rem;
}
.MuiAutocomplete-inputRoot {
  padding: unset !important;
}
select,
input[type="text"]{
  width: 100%;
  padding: 0.5rem 25% 0.5rem 0.5rem !important;
  border: 1px solid #79b5ff;
  border-radius: 4px;
  /* height: 1rem; */
}

}

