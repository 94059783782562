.messageBox {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    background-color: #F5F5F5;
    padding: 10px 5px 5px 10px;
    margin-top: 18px !important;
    /* width: fit-content; */
}

.messageContainer {
    padding-left: 25px;
    padding-top: 2px;
    /* height: 380px; */
    max-height: calc(100% - 50px);
    overflow-y: scroll;
    overflow-x: hidden;
}

@media only screen and (max-width:560px) {
    .messageContainer {
        height: calc(100vh - 200px);
        /* max-height: unset; */
        padding-bottom: 50px;
        flex-direction: column !important;
    }

    .commentInputForAt {
        position: unset !important;
        /* margin-left: 9px !important; */
        padding-top: 9px !important;
        margin-left: unset !important;
    }
}

.commentTextArea {
    width: 100vh;
    height: 3vh !important;
    overflow: hidden;
    margin-top: 1px;
    resize: none;
    font-size: 14px;
    padding-top: 8px;
}

.commentInputForAt {
    border: 1px solid #3D7CC9;
}

.messageTime {
    color: #3D7CC9;
    font-size: 10px;
    text-align: right;
}

.messageFrom {
    color: #666A70;
    font-size: 10px;
    margin-top: 5px;
    margin-left: 7px;
}

.messageMedia {
    border-radius: 8px;
    background-color: #FFFFFF;
    margin: 10px;
    height: 50px;
    text-align: center;
    cursor: pointer;
}

.imgDiv {
    display: inline-block;
    min-width: 2.2cm;
    text-align: center;
    vertical-align: middle;
}

.imgTxt {
    display: inline-block;
    vertical-align: middle;
    color: #3D7CC9;
}