.screen-header {
    position: relative;
    border-radius: 0 0 10px 10px;
    height: 4rem;
    background: #EAF1F9;
    z-index: 999;
}

.left-arrow {
    height: 4rem;
    margin-top: 1.2rem !important;
    margin-left: 1% !important;
    cursor: pointer;
}

.header-title {
    position: absolute;
    left: 45%;
}

.input-field-container {
    padding: 10px 20px 10px 25px;
    background-color: white;
    min-height: 80vh;
    height: auto;
}

.selectInput {
    height: 5vh;
    border: 1px solid #3D7CC9;
    border-radius: 5px;
    color: #818181 !important;
}

.selectLeftColWid {
    width: 85%;
}

.MuiAutocomplete-inputRoot {
    padding: unset !important;
}

.selectRightColWid {
    width: 38%;
}

.input-field-container .input-field {
    padding: 10px 0;
}

.text-area-input-field {
    padding: 10px;
}

.input-field>label {
    color: #00263E;
}

.input-field .text-field {
    width: 72vw;
    border: 1px solid #3D7CC9;
    border-radius: 8px;
    color: #00263E;
}

.border-sol-1 {
    border: 1px solid #3D7CC9;
}

.border-sol-2 {
    border: 2px solid #3D7CC9;
}

.text-field:focus {
    border: 1px solid #3D7CC9;
}

.title-h {
    height: 11vh;
}

.description-h {
    height: 15vh;
}



.btn-info {
    position: absolute;
    border: none;
    bottom: 9%;
    background-color: #3D7CC9;
    border-radius: 8px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    height: 6vh;
    width: 12vw;
    font-size: 18px;
}

.cancel-button {
    left: 6%;
}

.create-button {
    left: 50%;
}

.form-date {
    padding: 0 6px;
}

.btn-container {
    position: relative;
}

.character-limit {
    margin: 0;
    font-size: 12px;
    color: grey;
}

.selectedItem {
    color: #00263E;
}

@media only screen and (max-width:560px) {
    .btn-container {
        margin-top: 24% !important;
    }

    .btn-info {
        width: 33vw;
    }

    .input-field .text-field {
        width: 86vw;
    }

    .selectLeftColWid,
    .selectRightColWid {
        width: 100%;
    }

}

/* customizing material css */
.css-1ns1ofg-MuiInputBase-root-MuiOutlinedInput-root {
    width: 100%;
    height: 5vh;
}

.css-1ns1ofg-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #3D7CC9 !important;
}

svg[data-testid='CalendarMonthOutlinedIcon'] {
    color: #3D7CC9;
}

/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    color: #818181 !important;
} */
.selectInputToggle {
    height: 5vh;
    border: 1px solid #3D7CC9;
    border-radius: 5px;
}

/* .test .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    color: #00263E !important;
} */