.viewAllBar {
    background-color: #00263E;
    padding: 16px 0px 16px 24px !important;
}

.viewAllHead {
    color: white;
    /* text-align: center; */
}

.cancelIcon {
    color: white;
}
.buttonCheckIn Button{
    font-size: 12px;
    border: 1px solid #009817;
    height: 30px;
    width: 30px;
    margin-top: 5px;
    color: #009817;
    padding: 0;
    min-width: 35px;
    line-height: 0;
}
.buttonCheckIn {
    display: flex !important;
    flex-direction: column;
    font-size: 9px;
    color: #666A70;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.CheckoutButton{
    display: flex !important;
    flex-direction: column;
    font-size: 9px;
    color: #666A70;
    justify-content: center;
    align-items: center;
    gap: 4px;
}
.CheckoutButton Button{
    font-size: 12px;
    border: 1px solid #E00000;
    height: 30px;
    width: 30px;
    margin-top: 5px;
    color: #E00000;
    padding: 0;
    min-width: 35px;
    line-height: 0;
}
.screen-header {
    /* position: relative; */
    border-radius: 0 0 10px 10px;
    height: 4rem;
    background: #EAF1F9;

    position: fixed;
    display: block;
    width: 100%;
    z-index: 999;
    /* margin-top: 60px; */

}

.left-arrow {
    height: 4rem;
    margin-top: 1.2rem !important;
    margin-left: 1% !important;
}

.input-field-container {

    padding: 10px 20px 10px 25px;
    background-color: white;
    min-height: 80vh;
    height: auto;
    padding-top: 65px !important;
}

.selectInput {
    height: 6vh;
    border: 1px solid #3D7CC9;
    border-radius: 5px;
    color: #818181 !important;
}

.selectLeftColWid1 {
    width: 100%;
}

.selectLeftColWidSelector {
    width: 100%;
    height: 40px;
}

.selectLeftColWidSelector svg {
    color: #3D7CC9
}

.selectRightColWid {
    width: 38%;
}

.input-field-container .input-field {
    padding: 10px 0;
}

.forstatusSelector {
    width: 100%
}

.input-field>label {
    color: #00263E;
}

.input-field .text-field {
    width: 72vw;
    border: 1px solid #3D7CC9;
    border-radius: 8px;
}

.border-sol-1 {
    border: 1px solid #3D7CC9;
}

.border-sol-2 {
    border: 2px solid #3D7CC9;
}

.text-field:focus {
    border: 1px solid #3D7CC9;
}

.title-h {
    height: 11vh;
}

.description-h {
    height: 15vh;
}

.btn-info {
    position: absolute;
    border: none;
    bottom: 9%;
    background-color: #3D7CC9;
    border-radius: 8px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    height: 6vh;
    width: 12vw;
    font-size: 18px;
}

.cancel-button {
    left: 6%;
}

.create-button {
    left: 50%;
}

.form-date {
    padding: 0 6px;
}

.btn-container {
    position: relative;
}

.tabContainer {
    width: 100%;
    display: inherit;
    padding: 0;
}

.tabContainer>.tabItem {
    background-color: white;
    color: #00263E;
    padding: 15px 28px;
    border-radius: 8px 8px 0 0;
    font-weight: 600;

}

.mobile-screen {
    display: none;
}

.large-screen {
    display: block;
}

.status {
    margin: auto !important;
    font-weight: 600;
}

.jss12 {
    width: 100% !important;
}

.valueField {
    /* font-weight: 600; */
}

.dataContainer {
    font-size: 18px;
    color: #00263E;
    line-height: 20px;
}

.fieldContainer {
    margin-top: 20px;
}

.myBoxView {
    border: 1px solid #3D7CC9;
    border-radius: 8px;
    width: 100%;
    position: relative;
    height: 100vh;
    overflow-y: scroll;
}

.myBoxViewWithoutScroll form {
    background-color: #F7F7F7;
    border: 1px solid #3D7CC9;
    border-radius: 8px;
}

.myBoxViewWithoutScroll {
    border: 1px solid #3D7CC9;
    border-radius: 8px;
    width: 100%;
    position: relative;
    height: 450px;
    /* overflow-y: scroll; */
}


button:hover{
    background-color: #3D7CC9 !important;
}

.addButton {
    /* position: absolute!important;
    bottom: 10px;
    margin-left: 25px!important; */
    margin: 5px !important;
}

.addButton button {
    width: 140px;
}

.commentBox {
    padding: 2px 4px;
    display: flex;
    text-align: center;
    width: 99%;
    position: absolute;
    bottom: 0;
}

.commentInput {
    /* background: #FFFFFF!important; */
    border-radius: 8px;
    padding-left: 2px;
    padding-left: 10px !important;
    /* z-index: -1; */
}

.commentInputForAt {
    background: #FFFFFF !important;
    border-radius: 8px;
    padding-left: 2px;
    padding-left: 10px !important;
    color: black;
    /* z-index: -1; */
    position: absolute;
    margin-left: 50px;
    /* margin-top: 1px; */
    margin-top: -2px;
    padding-top: 7px;
    padding-bottom: 7px;
    /* width: 100%; */
    width: 114vh;
    overflow-x: scroll;
    white-space: nowrap;
    text-align: left !important;
    height: 20px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.sendButton {
    position: absolute !important;
    right: 0;
}

.commentInputForAt::-webkit-scrollbar {
    display: none;
}

.historyContainer {
    font-size: 14px;
    color: #00263E;
    padding: 20px;
}

.checkIn-img{
    pointer-events: none;
    height: 20px;
}

@media only screen and (max-width:560px) {

    html {
        overflow: hidden;
    }

    .commentBox {
        width: 98% !important;
        position: fixed;
        bottom: 0;
    }

    .myBoxViewWithoutScroll {
        height: auto;
        border: 0px;
    }

    .myBoxView {
        border: none;
        height: 80vh;
        max-height: calc(100vh - 230px);
    }

    .input-field-container {
        padding: 0;
        margin: 0;
        width: fit-content;
    }

    .btn-container {
        margin-top: 24% !important;
    }

    .btn-info {
        width: 33vw;
    }

    .dataContainer {
        padding: 0 20px;
        height: calc(100vh - 176px);
        overflow: scroll;
        padding-bottom: 65px;
        max-width: 100vw !important;
    }

    .form-title {
        margin: 0;
        font-size: 1rem;
        font-weight: normal;
    }

    .input-field .text-field {
        width: 86vw;
    }

    .selectLeftColWid,
    .selectRightColWid {
        width: 100%;
    }

    .selectInput {
        border-radius: 6px;
    }

    .mobile-screen {
        display: block;
    }

    .large-screen {
        display: none !important;
    }

    .valueField {
        font-size: 00.9rem;
    }

    .tabContainer {
        width: 100%;
        display: inline-flex;
        padding: 0;
        margin: 0;
        background-color: #eaf1f9;
        flex-direction: row;
    }

    .input-field-container .tab {
        width: 100%;
        max-width: 100vw;
    }

    .tabContainer>.tabItem {
        background-color: #3D7CC9;
        color: white;
        padding: 15px 27px;
        border-radius: 8px 8px 0 0;
        font-weight: 600;
        width: 100%;
        list-style-type: none;
        /* flex: 1; */
    }

    .tabContainer>.tab-active {

        background-color: white;
        color: #00263E;
        /* color: #3D7CC9; */
    }

    .historyContainer {
        font-size: 16px;
    }

    .commentInputForAt {
        /* width: 41vh; */
        width: 78%;
    }
}

/* customizing material css */
svg[data-testid='CalendarMonthOutlinedIcon'] {
    color: #3D7CC9;
}

#addToHomeScreen{
    display: none;
}
.head_grid{
    display: flex;
    justify-content: end;
}