.docUploadPop {
    width: 350px;
    height: 250px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    position: fixed;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    box-shadow: 2px 2px 6px #0000000D;
    border-radius: 5px;
    background-color: #FFFFFF;
  }

.uploaderHeader{
    background-color:#3D7CC9;
    color: #FFFFFF;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    height: 50px!important;
    vertical-align: middle;
    padding-top: 12px;
}

.uploadIcon{
    height: 70px;
    width: 70px;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    cursor: pointer;
}

.crossIcon{
    height: 15px;
    width: 15px;
    float: right;
    margin-right: 15px;
    cursor: pointer;
    margin-top: 4px;
}

.overlay {
    position: fixed;
    /* display: none; */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer;
  }

.fileInput{
    display: none;
}