.drop-message{
    padding-block: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.drop-attachment{
    border: 1px solid #3D7CC9;
    border-radius: 8px;
    font-size: 0.9rem;
    color: #3D7CC9;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-block: 4px;
    padding-left: 8px;
}

.dropImagesContainer{
    display: flex;
    padding: 0;
    margin: 20px 0;
    height: 100px;
    list-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
}

.dropImagesContainer>.dropListImage{
    margin-right: 15px;
    width: 170px;
    height: 80px;
    flex-shrink: 0;
    background: #e6e6e6;
    padding-bottom: 20px;
    border-radius: 5px;
}

.dropImagesContainer .dropImage{
    height: 100%;
    width: 90%;
    margin: 2% 2% 0 5%;
}

.dropImagesContainer .dropDocumentImage{
    width: fit-content;
    margin: 0 25%;
    height: 100%;
}

.caption{
    color: #00263E;
    font-size: 10px;
    margin: 0 5%;
}

.caption>p{
    display: inline;
    margin: 0;
}

.dropImagesContainer::-webkit-scrollbar{
    display: none;
}

@media only screen and (max-width:560px){

    .drop-attachment .drop-icon{
        margin-top: 0;
    }

        /* .drop-attachment>div{
            padding-block: 25px;
        } */

    .drop-attachment>.drop-text{
        font-size: 0.9rem;
        padding-left: 3%;
    }

    .dropImagesContainer{
        margin: 12px 0;
        height: 110px;
    }
    .dropImagesContainer>.dropListImage{
        padding-bottom: 28px;
    }

    /* .dropImagesContainer>.dropListImage{
        width: 22vw;
    } */
}