.title {
    color: #00263E;
    font-size: 18px;
    font-weight: unset;
}

.count {
    border: 1px solid #3D7CC9;
    border-radius: 6px;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 4px;
    font-weight: bold;
}

.myBox {
    z-index: 1;
    top: 0;
    background: #FFF;
    padding: 0;
    height: auto;
    /* overflow: unset; */
}

.mob-tostify {
    margin-top: 12px;
}

.taskId {
    color: #3D7CC9;
    text-decoration: underline;
    cursor: pointer;
}

.taskStatus {
    color: rgb(61, 124, 201);
    border: 1px solid rgb(61, 124, 201);
    border-radius: 6px;
    width: 160px;
    height: 25px;
    font-weight: 600;
}

.fixedLayout {
    height: 66vh;
    overflow-y: auto;
}

.noDataMsg {
    text-align: center;
    font-size: 20px;
    color: #00263E;
}

.myCard {
    margin-top: 25px;
    margin-right: 40px;
    margin-left: 40px;
    box-shadow: 0px 2px 8px #0000001A;
}

.myCardMobile {
    /* margin-top: 25px; 
    margin-right: 40px; 
    margin-left: 40px; */
    margin-top: 10px;
    box-shadow: 0px 2px 8px #0000001A;
}

.filterHead {
    padding-left: 20px;
    color: #3D7CC9;
    font-size: 14px;
    font-weight: bold;
}

.mobileLeftDateRange,
.mobileRightDateRange {
    position: absolute;
    z-index: 1;
    top: 70px;
    box-shadow: 1px 2px 2px 1px #e9e9e9;
    border-radius: 5px;
}

.mobileLeftDateRange {
    left: 20px;
}

.mobileRightDateRange {
    right: 16px;
}

.mobileDateRangeBtn {
    background: white;
    padding: 10px 0px 10px 0px;
    display: flex;
    justify-content: center;
    gap: 25px;
}

.mobileDateRangeBtn>button {
    border: none;
    padding: 4px 10px;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    background: #3D7CC9;
}

.datePkrField fieldset.MuiOutlinedInput-notchedOutline {
    border-color: green;
}
.datePkrField fieldset {
    border: 1px solid #3D7CC9;
}
.datePkr-btn {
    color: #818181;
    width: 90%;
    justify-content: space-between;
    background: white;
    border: 1px solid grey;
    border-radius: 5px;
    display: flex;
    align-items: center;
    position: relative;
    height: 40px;
    border: 1px solid #3D7CC9;
}
.datePkr-btn CalendarMonthIcon{
    color: #3D7CC9 !important;
}
.searchHead{
    display: flex;
    width: 100%;
    justify-content: right;
    align-items: center;
    position: relative;
}
.searchIcon{
z-index: 999;
}
.datePkr-btn>p {
    font-size: 16px;
}
.datePkr {
    /* margin-left: 20px !important; */
    margin-top: 10px !important;
}

.tabBar {
    padding-left: 20px;
}
button:hover {
    background-color: #1976d2;
    color: #fff;
}
.datePkr-btn :hover{
    background-color: none;
}

.tabBar button {
    background: white;
    border-radius: 6px 6px 0px 0px;
    color: #00263E;
    box-shadow: 0px -2px 3px #0000000D;
    border: 1px solid #FFFFFF;
    width: 120px;
    font-weight: 600;
}
.css-hyum1k-MuiToolbar-root {
    display: flex;
    justify-content: center;
}
.tabBar button[class^="-selected"],
button[class*="-selected"] {
    background: #3D7CC9;
    border-radius: 6px 6px 0px 0px;
    color: white !important;
}

.tabPanel {
    margin: 0 10px 0 10px;
    /* height: ; */
}

.tabPanel div {
    padding: unset;
    padding-left: 2px;
    box-shadow: none;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
    background-color: #3D7CC9 !important;
}

.accord {
    height: 20vh;
    overflow-y: scroll;
}

.accordHead {
    font-size: 14px !important;
    color: #00263E !important;
    font-weight: bold !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 300px;
}

.accordIcon {
    color: #3D7CC9;
}

.css-1ns1ofg-MuiInputBase-root-MuiOutlinedInput-root {
    width: 150px;
    height: 40px;
    border: unset !important;
}

.css-19kzrtu {
    padding: unset !important;
}

.MuiAccordionDetails-root {
    /* height: 48vh; */
    overflow-y: auto;
}

.description {
    white-space: nowrap;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 3px;
    margin-left: 5px;
}

.horizontalScroll {
    overflow-x: scroll;
    white-space: nowrap;
    text-align: left !important;
}

.horizontalScroll::-webkit-scrollbar {
    display: none;
}

.filterTab {
    box-shadow: 0px 2px 8px #0000001A;
    padding-bottom: 16px;
}

.dateContainerLeft,
.dateContainerRight {
    display: flex;
    position: relative;
}

.dateContainerRight {
    padding-right: 16px;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
}

.clearDate {
    background: #f5cccc;
    color: white;
    font-weight: bold;
    padding: 2px 4px;
    border-radius: 5px;
    font-size: 12px;
    display: inline-block;
    margin-top: 3%;
    height: fit-content;
    position: absolute;
}

.dateContainerLeft .clearDate {
    right: -8px;
}

.dateContainerRight .clearDate {
    right: 8px;
}

.bottomButton {
    width: 140px;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.filterWrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.atTagOptions {
    position: absolute;
    height: 120px;
    overflow-y: auto;
    width: 230px;
    top: -126px;
    background-color: white;
    z-index: 999;
    overflow-x: hidden;
}

.atTagOptions ul {
    list-style-type: none;
    text-align: left;
}

.atTagOptions ul li {
    padding: 5px;
    cursor: pointer;
}

.atTagOptions ul li:hover {
    background: lightgrey !important;
}

.appBarTop {
    /* position: absolute; */
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    position: sticky;
}

.visitDate {
    padding-top: 24px;
}

.taskEditHeading {
    position: fixed;
    width: 96%;
    height: 3.5rem;
    display: flex;
    padding: 0px 2%;
    align-items: center;
    border-radius: 0px 0px 10px 10px;
    justify-content: space-between;
    background-color: #EAF1F9;
    z-index: 999;
}

.taskEditComp {
    padding-top: 1px;
}

.taskEditBody {
    padding: 40px;
    padding-top: 70px !important;
}

.nameCircle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-family: roboto, arial;
    color: black;
    text-align: center;
    background: orange;
    /* position: absolute; */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.filterListDisplay {
    position: absolute;
    font-size: 10px;
    /* margin-top: -12px; */
    left: 0;
    top: -14px;
    color: #1976d2;
    font-weight: bold;
}

.mobHead {
    font-size: 16px;
    padding-left: 10px;
}

.cancelFilter {
    float: right;
    color: red !important;
    margin-top: -5px;
    cursor: pointer;
}

.cancelFilter1 {
    position: absolute;
    right: 0;
    float: right;
    color: red !important;
    margin-top: -5px;
    cursor: pointer;
}

.makeStyles-forTextField-3 {
    width: 170px !important;
}

.visitDateDesktop {
    padding-top: 12px;
}


/* .makeStyles-forTextField-9,
.makeStyles-forTextField-6 {
    width: fit-content !important;
} */

.btn-box {
    max-width: 100vw;
}

.btn-scroll>div {
    max-width: 100vw;
    overflow: auto !important;
}

.accordian-container {
    max-height: calc(100vh - 450px);
    overflow: auto;
}

.notificationList:last-child {
    margin-bottom: 20px;
}

.notificationButton:hover {
    border: 1px solid;
    box-shadow: 5px 5px #888888;
    background-color: white;
    color: #00263E;
}

.notificationButton {
    border: 2px solid;
    border-radius: 7px;
}
.dateText{
    margin: 0px 8px 4px 0px;
    text-align: end;
    font-size: 10px;

}

.loaderContainer {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #d9d9d91f;
    display: grid;
    place-items: center;
    z-index: 10;
    backdrop-filter: blur(2px);
}

.loader {
    animation: rotation 2s linear infinite;
}

.taskCreateButton {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    color: blue;
}

.taskCreateButton button {
    background: none;
    border: none;
    color: blue;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

@media only screen and (max-width:560px) {
    .myBox {
        height: 100vh;
    }
}